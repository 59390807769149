export const SUSPECTS = [
  { color: '#FF0000', name: 'Scarlet' }, // Red
  { color: '#AF1DCD', name: 'Plum' }, // Purple
  { color: '#04A8F0', name: 'Peacock' }, // Blue
  { color: '#66A33E', name: 'Green' }, // Green
  { color: '#D60394', name: 'Orchid' }, // Pink
  { color: '#FCB904', name: 'Mustard' }, // Yellow
];

export const WEAPONS = [
  { name: 'Candlestick' },
  { name: 'Dagger' },
  { name: 'Lead Pipe' },
  { name: 'Revolver' },
  { name: 'Rope' },
  { name: 'Wrench' },
];

export const LOCATIONS = [
  { name: 'Ballroom' },
  { name: 'Billiard Room' },
  { name: 'Conservatory' },
  { name: 'Dining Room' },
  { name: 'Hall' },
  { name: 'Kitchen' },
  { name: 'Library' },
  { name: 'Lounge' },
  { name: 'Study' },
];

export type IOption = ' ' | 'X' | '?' | 'O';
export const OPTIONS: IOption[] = [' ', 'X', '?', 'O'];
