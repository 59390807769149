import { user_click_item_header, user_open_control_modal } from 'store/actions';
import { Store } from 'store/store';
import { SUSPECTS } from '../constants';

const Players = () => {
  const dispatch = Store.useDispatch();

  return (
    <>
      <div className="grid-item" onClick={() => dispatch(user_open_control_modal)}>
        Players
      </div>

      {SUSPECTS.map(({ color }, index) => {
        const playerId = 'player-' + index;
        return (
          <div
            key={playerId}
            className="grid-item"
            style={{ backgroundColor: color }}
            onClick={() => dispatch(user_click_item_header(playerId))}
          />
        );
      })}
    </>
  );
};

export default Players;
