import { Store } from 'store/store';
import { user_click_box } from 'store/actions';

const Boxes: React.FC<{ name: string }> = ({ name }) => {
  const dispatch = Store.useDispatch();
  const items = Store.useSelector((s) => s.items);
  const toggles = Store.useSelector((s) => s.toggles);

  const components = items[name].map((option, index) => {
    let className = 'grid-item';
    if (option === 'O') {
      className += ' lightblue';
    } else if (toggles[name] || toggles['player-' + index]) {
      className += ' lightgray';
    }

    return (
      <div
        key={name + index}
        className={className}
        onClick={() => dispatch(user_click_box(name, index))}
      >
        {option}
      </div>
    );
  });

  return <>{components}</>;
};

export default Boxes;
