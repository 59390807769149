import { OPTIONS } from '../constants';
import { IAction, IState, initialState } from 'store/store';

export const load_state_from_local_storage: IAction = (state) => {
  const data = localStorage.getItem('clue_notes');
  if (data) resetState(state, JSON.parse(data));
};

export const user_click_box = (name: string, index: number): IAction => {
  return function user_click_box(state) {
    const opt = state.items[name][index];
    const nextOpt = OPTIONS[(OPTIONS.indexOf(opt) + 1) % OPTIONS.length];
    state.items[name][index] = nextOpt;
  };
};

export const user_click_item_header = (name: string): IAction => {
  return function user_click_item_header(state) {
    if (!(name in state.toggles)) {
      state.toggles[name] = false;
    }
    state.toggles[name] = !state.toggles[name];
  };
};

export const user_open_control_modal: IAction = (state) => {
  state.openControlModal = true;
};

export const user_open_confirm_modal: IAction = (state) => {
  state.openConfirmResetModal = true;
};

export const on_close_modals: IAction = (state) => {
  state.openConfirmResetModal = false;
  state.openControlModal = false;
};

export const user_reset_notes: IAction = (state) => {
  resetState(state, initialState);
  on_close_modals(state);
};

export const user_click_clear_lines_button: IAction = (state) => {
  state.toggles = {};
  on_close_modals(state);
};

// Utils
const resetState = (state: IState, newState: IState) => {
  state.items = newState.items;
  state.toggles = newState.toggles;
};
