import type { Dispatch } from 'react';
import { createStore } from 'utils/createStore';
import { SUSPECTS, WEAPONS, LOCATIONS, OPTIONS, IOption } from '../constants';

const items: Record<string, IOption[]> = {};
[...SUSPECTS, ...WEAPONS, ...LOCATIONS].forEach(({ name }) => {
  items[name] = Array(SUSPECTS.length).fill(OPTIONS[0]);
});

// initial state
export const initialState = {
  openControlModal: false,
  openConfirmResetModal: false,
  toggles: {} as Record<string, boolean>,
  items,
};

// types
export type IState = typeof initialState;
export type IAction = (state: IState) => void;
export type IAsyncAction = (dispatch: Dispatch<IAction>) => Promise<void>;

// store
export const Store = createStore<IState>(initialState);
