import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    // Clear local storage
    localStorage.clear();
  }

  public render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return <h2>Sorry.. there was an error. Please refresh the page and reset the notes.</h2>;
  }
}

export default ErrorBoundary;
