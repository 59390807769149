import ReactModal from 'react-modal';
import { Store } from 'store/store';
import {
  on_close_modals,
  user_click_clear_lines_button,
  user_open_confirm_modal,
  user_reset_notes,
} from 'store/actions';

ReactModal.setAppElement('#root');
const customStyles = {
  content: {
    top: '30%',
    height: '170px',
    maxWidth: '360px',
    margin: '0 auto',
  },
};

export const ControlModal: React.FC = () => {
  const dispatch = Store.useDispatch();
  const open = Store.useSelector((s) => s.openControlModal);

  return (
    <ReactModal isOpen={open} style={customStyles} onRequestClose={() => dispatch(on_close_modals)}>
      <div className="modal">
        {/* Reset Button */}
        <div className="btn" onClick={() => dispatch(user_open_confirm_modal)}>
          Reset all the notes
        </div>

        {/* Clear Button */}
        <div className="btn" onClick={() => dispatch(user_click_clear_lines_button)}>
          Clear gray lines
        </div>
      </div>
    </ReactModal>
  );
};

export const ConfirmResetModal: React.FC = () => {
  const dispatch = Store.useDispatch();
  const open = Store.useSelector((s) => s.openConfirmResetModal);

  return (
    <ReactModal isOpen={open} style={customStyles} onRequestClose={() => dispatch(on_close_modals)}>
      <div className="modal">
        {/* Confirm Button */}
        <div className="btn" onClick={() => dispatch(user_reset_notes)}>
          Confirm to reset all the notes!
        </div>
      </div>
    </ReactModal>
  );
};
