import { user_click_item_header } from 'store/actions';
import { Store } from 'store/store';
import { LOCATIONS } from '../constants';
import Boxes from './Boxes';

const Locations = () => {
  const dispatch = Store.useDispatch();

  return (
    <>
      <div className="grid-item grid-hr">Locations</div>

      {LOCATIONS.map(({ name }) => [
        // Location Header
        <div
          key={name}
          className="grid-item"
          onClick={() => dispatch(user_click_item_header(name))}
        >
          {name}
        </div>,

        // Boxes
        <Boxes key={name + '-boxes'} name={name} />,
      ])}
    </>
  );
};

export default Locations;
