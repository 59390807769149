import './App.scss';
import { useEffect } from 'react';
import { Store } from 'store/store';
import { load_state_from_local_storage } from 'store/actions';
import ErrorBoundary from './ErrorBoundary';
import Players from './Players';
import Suspects from './Suspects';
import Weapons from './Weapons';
import Locations from './Locations';
import { ControlModal, ConfirmResetModal } from './Modals';

function AppWrapper() {
  return (
    <ErrorBoundary>
      <Store.Provider>
        <App />
      </Store.Provider>
    </ErrorBoundary>
  );
}

function App() {
  // Load state from local storage
  const dispatch = Store.useDispatch();
  useEffect(() => dispatch(load_state_from_local_storage), [dispatch]);

  return (
    <div className="grid-container">
      <Players />
      <Suspects />
      <Weapons />
      <Locations />
      <ControlModal />
      <ConfirmResetModal />
    </div>
  );
}

export default AppWrapper;
